<template lang="html">
    <div class="settingContent">
		<div class="handleBox flex flex-jc-sb flex-ai-c">
	       <h3 style="">服务费记录 <span v-show="this.$route.query.name">{{' - '+this.$route.query.name}}</span></h3>
			<div class="handlebox flex flex-ai-c">
				<!-- <el-input placeholder="商户" size="small" style="width:200px;margin-right:10px;" v-model="search.searchValue" @input="getList">
					<i slot="suffix" class="el-input__icon el-icon-search"></i>
				</el-input> -->
				<!-- <el-date-picker style="width:230px;margin-right:10px;" v-model="search.date" type="daterange" align="right" size="small" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" format="yyyy-MM-dd"
				value-format="yyyy-MM-dd" @change="getList" @blur="" @focus=""></el-date-picker> -->
				<!-- <p style="margin-left:auto;font-size:14px">共缴纳：{{totalMoney}}元</p> -->
			</div>
		</div>
        <div class="contentBox" style="width:100%;padding:0 40px">
			<el-table :data="list" size="medium" :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" :max-height="tableHeight">
                <el-table-column prop="company.name" label="商户"></el-table-column>
                <el-table-column prop="fee" label="金额(元)"></el-table-column>
				<el-table-column prop="ext_from" label="开始时间"></el-table-column>
				<el-table-column prop="ext_to" label="结束时间"></el-table-column>
				<el-table-column prop="num" label="续费天数"></el-table-column>
                <el-table-column prop="create_time" label="续费时间"></el-table-column>
				<!-- <el-table-column prop="order_no" label="微信订单号"></el-table-column> -->
            </el-table>
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            search: {
                searchValue: '', //搜索值
                date: '', //时间段
            },
            value: {
                name: ''
            },
            tableHeight: 660,
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数

            totalMoney: '',

            // 时间段配置
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
        }
    },
    mounted: function() {
        this.init();
    },
    methods: {
        init: function() {
            // this.autoHeight();
            this.getList();
        },
        //自动高度
        autoHeight: function() {
            var duoyu = 60 + 70 + 61 + 30 + 65 + 15 + 40;
            this.tableHeight = document.documentElement.clientHeight - duoyu;
            window.onresize = () => {
                this.tableHeight = document.documentElement.clientHeight - duoyu;
            }
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
        // 获取列表
        getList: function() {
            var url = '/admin.companyUse/index';
            this.http.post(url, {
                curr: this.curr,
                row: this.row,
                wd: this.search.searchValue,
                company_id: this.$route.query.id,
				company_name: this.$route.query.name,
                startTime: this.search.date[0] ? this.search.date[0] : '',
                endTime: this.search.date[1] ? this.search.date[1] : '',
            }).then(res => {
                this.list = res.list;
                this.curr = res.curr;
                this.pages = res.pages;
                this.count = res.count;
                this.totalMoney = res.totalMoney;
            });
        },
    }
}
</script>
